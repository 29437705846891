<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Tracking</label>
      <div class="col-6">
            <input type="text" ref="trackingNumber" v-model="form.trackingNumber" class="form-control" @keyup.enter="processTracking"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Customer</label>
      <div class="col-3">
            <input type="text" ref="customer" v-model="form.customer" class="form-control" @keyup.enter="processCustomer"/>
      </div>
    </div>
    <div class="mb-3 row">
        <label class="col-1 col-form-label">Scan UPC</label>
        <div class="col-6">
              <input type="text" ref="upc" v-model="upc" class="form-control" style="width:500px;" @keyup.enter="processUpc"/>
        </div>
      </div>
    <div v-for="(item, index) in form.productList" :key="index">
      <div class="mb-3 row">
        <label class="col-1 col-form-label">Product</label>
        <div style="width: 650px;">
              <input type="text" v-model="item.upc" class="form-control" style="width:500px; display: inline-table;" placeholder="UPC"/>
              <input type="text" v-model="item.qty" class="form-control" style="width:80px; margin-left: 2px; display: inline-table;" placeholder="Quantity"/>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-1 col-form-label">Product Name</label>
        <div class="col-6">
              <input type="text" v-model="item.productName" class="form-control"/>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Check In</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "PackageEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id || "",
        productList: []
      },
      upc: "",
      action: this.$route.query.action || "",
      breadcrumbs: [{ name: "Package", link: "/" }, { name: (this.$route.query.id ? "Update" : "New") + " Package " + (this.$route.query.action || "") }],
      alertContent: "",
      alertClass: "alert-danger"
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      } else {
        this.$refs.trackingNumber.focus();
      }
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      if (this.action && this.action === "COPY") {
        this.form.id = "";
      }
      axios.post("/package", this.form)
        .then(() => {
          this.submitted = false;
        })
        .catch((error) => {
          this.alertClass = "alert-danger";
          this.alertContent = error.response ? error.response.data : error;
          this.submitted = false;
        });
    },
    processTracking() {
      this.$nextTick(function() {
        this.$refs.customer.focus();
      });
    },
    processCustomer() {
      this.$nextTick(function() {
        this.$refs.upc.focus();
      });
    },
    processUpc() {
      var productList = this.form.productList;
      var upc = this.upc;
      this.$nextTick(function() {
        console.log(productList.toString());
        if (productList.some(item => item.upc === upc)) {
          productList.map( item => { 
            if (item.upc === upc) {
              item.qty++;
            } 
          })
        } else {
          productList.push({
            upc: upc,
            qty: 1,
            productName: ""
          })
        }
      });
    },
    getObject() {
      axios
      .get("/package/" + this.form.id)
      .then((response) => {
        this.form = response.data;
      })
      .catch((error) => {
        this.alertClass = "alert-danger";
        this.alertContent = error.response ? error.response.data : error;
      });
    }
  },
};
</script>
